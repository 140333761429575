import axios from "axios";
import _ from "lodash";
import urls from "../../../shared/urls";
import moment from "moment";

const namespaced = true;
var qs = require("qs");
const getDefaultFilters = () => {

  const defaultTemplatesFilter = {
    personal: true,
    multiUser: false,
    byAssignedUser: false
  }

  return {
    templateName: null,
    createdFrom: null,
    createdTo: null,
    selectedBillTos: [],
    selectedConsignees: [],
    selectedShippers: [],
    selectedShipperCities: [],
    selectedShipperStates: [],
    selectedSuppliers: [],
    selectedSupplierCities: [],
    selectedSupplierStates: [],
    selectedAccountOfs: [],
    selectedCities: [],
    selectedStates: [],
    selectedPOs: null,
    getSelectedCommodities: [],
    selectedAdditionalNotes: null,
    datesExpanded: false,
    advancedFieldsExpaned: false,
    basicFieldsExpaned: true,
    selectedAmountUnitOfMeasure: null,
    templateFilters: defaultTemplatesFilter,
    reload: false
  };
};

const state = {
  total: 0,
  currentPage: 1,
  orderTemplates: [],
  isFilterApplied: false,
  filterObject: { ...getDefaultFilters() }
};
const mutations = {
  SET_ORDER_TEMPLATES: (state, payload) => {
    state.orderTemplates = payload.templates;
    state.currentPage = payload.page;
    state.pageSize = payload.pageSize;
    state.total = payload.total;
  },
  DELETE_ORDER_TEMPLATE: (state, payload) => {
    var newArr = _.filter(state.orderTemplates, function (obj) {
      return obj.templateId != payload;
    });
    state.orderTemplates = newArr;
  },
  SET_SEARCH_TERM: (state, payload) => {
    state.searchTerm = payload;
  },
  SET_CURRENT_PAGE: (state, payload) => {
    state.currentPage = payload;
  },
  SET_PAGINATION_PAGE: (state, payload) => {
    state.currentPage = payload;
  },
  RESET_RELOAD: (state) => {
    // there is no watch for searchTerm, add a reload flag to get around
    if (state.searchTerm) {
      state.searchTerm = '';
      state.reload = true;
    }
  },
  SET_TEMPLATE_FILTERS: (state, payload) => {
    state.filterObject = payload;
  },
  SET_IS_FILTER_APPLIED: (state, payload) => {
    state.isFilterApplied = payload;
  },
  CLEAR_TEMPLATE_FILTERS: (state) => {

    const tf = getDefaultFilters();

    //deep-merge
    _.merge(state, tf);

    state.isFilterApplied = false;
  }
};

const actions = {
  async getAllOrderTemplates(context, requestData) {


    var createdEndDate = moment(requestData.filters.createdTo, "MM-DD-YYYY").add(1, 'day').format(
      "YYYY-MM-DD"
    );
    var createdFromDate = moment(requestData.filters.createdFrom, "MM-DD-YYYY").format(
      "YYYY-MM-DD"
    );
   
    var reqParams = {
      page: requestData.currentPage,
      pageSize: 10
    };
    
    //ref-variable
    Object.assign(reqParams,
      (
        requestData.filters.templateName && { "searchTerm": requestData.filters.templateName }
      ),
      (
        requestData.filters.selectedBillTos && { "billTo": requestData.filters.selectedBillTos.map(a => a.id) }
      ),
      (
        requestData.filters.selectedAccountOfs && { "accountOf": requestData.filters.selectedAccountOfs.map(a => a.id) }
      ),
      (
        requestData.filters.selectedConsignees && { "deliverySite": requestData.filters.selectedConsignees.map(a => a.id) }
      ),
      (
        requestData.filters.selectedCities && { "deliveryCity": requestData.filters.selectedCities }
      ),
      (
        requestData.filters.selectedStates && { "deliveryState": requestData.filters.selectedStates.map(a => a.abbreviation) }
      ),
      (
        requestData.filters.selectedShippers && { "shipper": requestData.filters.selectedShippers.map(a => a.id) }
      ),
      (
        requestData.filters.selectedShipperCities && { "shipperCity": requestData.filters.selectedShipperCities }
      ),
      (
        requestData.filters.selectedShipperStates && { "shipperState": requestData.filters.selectedShipperStates.map(a => a.abbreviation) }
      ),
      (
        requestData.filters.selectedSuppliers && { "supplier": requestData.filters.selectedSuppliers.map(a => a.id) }
      ),
      (
        requestData.filters.selectedSupplierCities && { "supplierCity": requestData.filters.selectedSupplierCities }
      ),
      (
        requestData.filters.selectedSupplierStates && { "supplierState": requestData.filters.selectedSupplierStates.map(a => a.abbreviation) }
      ),
      (
    
        requestData.filters.getSelectedCommodities && { "product": requestData.filters.getSelectedCommodities.map(a => a.commodityCode) }
      ),
      (
        requestData.filters.selectedPOs && { "Po": requestData.filters.selectedPOs }
      ),
      (
        requestData.filters.selectedAdditionalNotes && { "additionalNotes": requestData.filters.selectedAdditionalNotes }
      ),
      
      (
        requestData.filters.createdFrom && { "CreatedDateStart": createdFromDate }
      ),
      (
        requestData.filters.createdTo && { "CreatedDateEnd": createdEndDate }
      ),
      (
        requestData.personal && { "personal": requestData.personal }
      ),
      (
        requestData.multiUser && { "multiUser": requestData.multiUser }
      ),
      (
        requestData.byAssignedUser && { "byAssignedUser": requestData.byAssignedUser }
      )

    )

    var orderTemplates = await  axios.get(urls.LoadTenderTemplateUrl, {
      headers: {
        Prefer: requestData.preferHeader,
        Pragma: "no-cache"
      },
      params: reqParams,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "repeat"
        });
      }
    });
    
    context.commit("SET_ORDER_TEMPLATES", orderTemplates.data);
  },
  async getOrderTemplate(context, requestData) {
    var orderTemplates = await axios.get(
      `${urls.LoadTenderTemplateUrl}/${requestData.id}`,
      {
        headers: {

        }
      }
    );

    context.commit("SET_ORDER_TEMPLATES", orderTemplates.data);
  },
  async deleteOrderTemplate(context, requestData) {
    var response = await axios.delete(
      `${urls.LoadTenderTemplateUrl}/${requestData.id}`
    );

    if (response.status == 204) {
      context.commit("DELETE_ORDER_TEMPLATE", requestData.id);
    }
  },
  setSearchTerm(context, term) {
    context.commit("SET_SEARCH_TERM", term);
  },
  setCurrentPage(context, pageNum) {
    context.commit("SET_CURRENT_PAGE", pageNum)
  },
  setPagination(context, page) {
    context.commit("SET_PAGINATION_PAGE", page);
  },
  resetReload(context) {
    context.commit("RESET_RELOAD")
  },
  setIsFilterApplied(context, payload = false) {
    context.commit("SET_IS_FILTER_APPLIED", payload)
  },
  setTemplateFilters(context, payload) {
    context.commit("SET_TEMPLATE_FILTERS", payload)
  },
  
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
